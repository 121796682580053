import React, { FC } from 'react'
import { WorkPlaceDetailed } from '@models/workPlace'
import EmployeesCountField from '@templates/EmployeesCountField'

type Props = {
  workPlace: WorkPlaceDetailed
}

const Reviews: FC<Props> = ({ workPlace }) => {
  const { jobConditionAssessment, subdivision } = workPlace
  const {
    countEmployeesSoutNotReviewed,
    countEmployeesSoutAvailable,
    countEmployeesSoutReviewed,
  } = jobConditionAssessment || {}

  return (
    <>
      <EmployeesCountField
        label="Сотрудники, которым доступна карта СОУТ"
        countEmployees={countEmployeesSoutAvailable}
        division={{
          value: subdivision['@id'],
          label: subdivision.title,
        }}
        isAccordionField
      />
      {/* TODO фильтр при клике */}
      <EmployeesCountField
        label="Сотрудники, которые ознакомились с картой СОУТ"
        countEmployees={countEmployeesSoutReviewed}
        isAccordionField
      />
      {/* TODO фильтр при клике */}
      <EmployeesCountField
        label="Сотрудники, которые не ознакомились с картой СОУТ"
        countEmployees={countEmployeesSoutNotReviewed}
        isAccordionField
      />
    </>
  )
}

export default Reviews
