import React, { FC } from 'react'
import { WorkPlaceDetailed } from '@models/workPlace'
import { Label } from '@organs/AccordionBodyField/styled'
import { useGetJobConditionAssessmentsWarrantiesQuery } from '@services/jobConditionAssesments'
import Loader from '@atoms/Loader'

type Props = {
  workPlace: WorkPlaceDetailed
}

const Warranties: FC<Props> = ({ workPlace }) => {
  const { jobConditionAssessment } = workPlace
  const { '@id': id } = jobConditionAssessment || {}
  const { isLoading, data } = useGetJobConditionAssessmentsWarrantiesQuery(id)

  if (isLoading) return <Loader isLoading />

  if (!data) return <h3>Что-то пошло не так</h3>

  return (
    <table>
      <tr>
        <th>
          <Label>Гарантии и компенсации</Label>
        </th>
        <th>
          <Label>Требуемая</Label>
        </th>
        <th>
          <Label>Фактическая</Label>
        </th>
        <th>
          <Label>Основания</Label>
        </th>
      </tr>
      {data.items?.map(({ title, required, actual, reason }) => (
        <tr>
          <td>{title}</td>
          <td>{required ? 'Да' : 'Нет'}</td>
          <td>{actual ? 'Да' : 'Нет'}</td>
          <td>{reason}</td>
        </tr>
      ))}
    </table>
  )
}

export default Warranties
