import { FC } from 'react'
import { compact } from 'lodash'
import useFormProcessor from '@hooks/useFormProcessor'
import FileErrorComponent from '@templates/FileErrorComponent'
import { File } from '@models/file'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useJobFields from '@hooks/useJobFields'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { Field } from '@templates/AddNewItemModal/Content'
import { fileField } from '@templates/AddNewItemModal/templates'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import { useCreateJobConditionAssessmentsFromXlsMutation } from '@services/jobConditionAssesments'
import { StyledUL, Wrapper } from './styled'

type FormValues = {
  file: File
}

type Props = {
  handleCloseModal: () => void
}

// TODO
const JobConditionAssessmentsFromFileXlsModal: FC<Props> = ({
  handleCloseModal,
}) => {
  const [post, postData] = useCreateJobConditionAssessmentsFromXlsMutation()

  const methods = useFormProcessor()
  const { company, filial } = useJobFields(methods)

  const handleSubmit = (form: FormValues) => {
    void post({
      xlsFile: form.file?.['@id'],
    })
  }

  const line1: Field[] = compact([company?.(), filial?.()])
  const line2: Field[] = [
    fileField({
      name: 'file',
      label: 'Файл',
      maxSizeMegaBytes: 50,
      formats: ['xls'],
    }),
  ]

  return (
    <AddNewItemModalLayout
      {...postData}
      customFullTitle="Добавить рабочие места"
      methods={methods}
      onSubmitForm={handleSubmit}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
      successContentAddTitle="Все рабочие места успешно добавлены"
    >
      <Wrapper>
        <DescriptionWrapper>
          <StyledUL>
            <li>
              Здесь вы можете загрузить сразу несколько карт СОУТ, заполненных в
              одном xlsx-файле.
            </li>
            <li>После загрузки будут созданы карты СОУТ к ним.</li>
            <li>
              В качестве Подразделений и Должностей указывайте те названия,
              которые указаны у вас на платформе. <br /> Если названия будут
              отличаться, то вам нужно будет их указать вручную в созданных
              картах СОУТ.
            </li>
            <li>
              Если рабочее место декларируемое, то укажите “Да” в столбце
              “Декларируемое РМ”.
            </li>
          </StyledUL>
        </DescriptionWrapper>
      </Wrapper>
      <DownloadExampleButton
        templateLink="/import_job_condition_assessments.xlsx"
        title="Школа экспертов — Шаблон загрузки карт СОУТ"
        description="Загрузите файл .xls, составленный по образцу, размер файла: до 50 мб"
      />
      <LineFields fields={line2} />
      <LineFields fields={line1} />
    </AddNewItemModalLayout>
  )
}

export default JobConditionAssessmentsFromFileXlsModal
