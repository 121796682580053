import { FC } from 'react'
import { compact } from 'lodash'
import useFormProcessor from '@hooks/useFormProcessor'
import FileErrorComponent from '@templates/FileErrorComponent'
import { File } from '@models/file'
import { useCreateJobConditionAssessmentsFromXmlMutation } from '@services/jobConditionAssesments'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useJobFields from '@hooks/useJobFields'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { Field } from '@templates/AddNewItemModal/Content'
import { fileField } from '@templates/AddNewItemModal/templates'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import {
  StyledUL,
  Wrapper,
} from '@modals/JobConditionAssessmentsFromFileXlsModal/styled'
import Title from '@atoms/Title'

type FormValues = {
  file: File
}

type Props = {
  handleCloseModal: () => void
}

// TODO
const WorkPlacesFromFileXmlModal: FC<Props> = ({ handleCloseModal }) => {
  const [post, postData] = useCreateJobConditionAssessmentsFromXmlMutation()

  const methods = useFormProcessor()
  const { company, filial } = useJobFields(methods)

  const handleSubmit = (form: FormValues) => {
    void post({
      xmlFile: form.file?.['@id'],
    })
  }

  const line1: Field[] = compact([company?.(), filial?.()])
  const line2: Field[] = [
    fileField({
      name: 'file',
      label: 'Файл',
      maxSizeMegaBytes: 50,
      formats: ['xls'],
    }),
  ]

  return (
    <AddNewItemModalLayout
      {...postData}
      customFullTitle="Добавить рабочие места"
      methods={methods}
      onSubmitForm={handleSubmit}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
      successContentAddTitle="Все рабочие места успешно добавлены"
    >
      <Wrapper>
        <DescriptionWrapper>
          <Title mySize="h1small">
            Загрузить файл xml с результатами оценки СОУТ
          </Title>
          Такой файл вам передаёт в архиве оценивающая компания. Распакуйте
          архив и загрузите только xml-файл (обычно, называется report.xml).
          <StyledUL>
            <li>
              После загрузки будут созданы рабочие места и карты СОУТ к ним.
            </li>
            <li>
              Если в отчёте использовались те же названия Подразделений и
              Должностей, что у вас загружены на платформе, то карты будут
              прикреплены к ним.
            </li>
            <li>
              Если названия будут отличаться, то вам нужно будет их указать
              вручную в созданных картах СОУТ.
            </li>
          </StyledUL>
        </DescriptionWrapper>
      </Wrapper>
      <DownloadExampleButton
        templateLink="/import_external_educations.xlsx"
        title="Школа экспертов — Загрузка прошлых прохождений"
        description="Загрузите файл .xls, составленный по образцу, размер файла: до 50 мб"
      />
      <LineFields fields={line2} />
      <LineFields fields={line1} />
    </AddNewItemModalLayout>
  )
}

export default WorkPlacesFromFileXmlModal
